.login {
  display: grid;
  place-items: center;
  height: 100vh;
}

.login > button {
  width: 300px;
  background-color: #2e81f4;
  color: #eff2f5;
  font-weight: 800;
}

.login > button:hover {
  background-color: white;
  color: #2e81f4;
}

.login > div {
  display: flex;
  flex-direction: column;
}

.login > div > img {
  object-fit: contain;
  height: 150px;
}
